import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CHeroImg,
  LPlan,
  CSectTitle,
  CDefinition,
  CMedia,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'la-vie arts',
            ja: 'ラヴィ・アーツ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/facilities/laviearts/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/facilities/laviearts/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宿泊',
                path: '/stay/',
              },
              {
                label: '館内施設',
                path: '/stay/facilities/',
              },
            ],
            current: {
              label: 'LA-VIE ARTS（ラヴィ・アーツ）',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect02 u_pb120_sp u_pb160_pc">
        <LWrap>
          <h2 className="c_headingLv2">
            新郎新婦とフォトグラファーが
            <br />
            一緒に作る「共同作品」
          </h2>
          <p className="c_sectLead u_mb30">
            お客様とじっくりコミュニケーションをとったうえで、お二人の自然な表情、豊かな表現シーンをフォトグラファーの個性や感性で捉えています。
            <br />
            写真は無限の可能性があります。ただきれいな写真ではなく、ハートのある写真を追求したい。
            <br />
            さぁ、大切な一日をラヴィ・アーツとともに･･･。
          </p>
          <CHeroImg
            exClass="u_mbExLarge"
            img={{
              src: '/assets/images/stay/facilities/laviearts/img_hero.png',
              alt: '',
            }}
          />
          <h2 className="c_headingLv2">フォトプラン</h2>
          <CMedia
            exClass=""
            data={[
              {
                img: {
                  src: '/assets/images/stay/facilities/laviearts/img_laviearts.png',
                  alt: '',
                },
                title: '',
                text: (
                  <>
                    心地よく落ち着いた雰囲気のスタジオ、ガーデンや館内でリラックスしながら、幸せな笑顔、大切な想い出を写真に残しませんか？
                    <br />
                    <br />
                    ラヴィ・アーツでは、ご結婚式のお写真はもちろん、様々な“一生に一度だけの大切な瞬間”を撮影いたします。
                    <br />
                    人生にすてきな写真を...。
                  </>
                ),
              },
            ]}
          />
          <CSectTitle
            title={{ ja: <>営業時間｜ご利用案内</>, en: <>GUIDE</> }}
          />
          <CDefinition
            exClass="u_mb30"
            data={[
              {
                title: <>営業時間</>,
                text: <>12：00～18：00</>,
              },
              {
                title: <>定休日</>,
                text: <>火曜日（祝日を除く）</>,
              },
              {
                title: <>お問合せ</>,
                text: <>022-377-4530</>,
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
